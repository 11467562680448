<template>
  <div>
    <b-card no-body class="mx-n3">
      <b-row>
        <b-col>
          <XtendHeatmap :url="urls.topAsUrl" :timeRange="timeRange" />
        </b-col>
      </b-row>
      <b-row class="mx-1 mt-2 mb-1" align-h="between">
        <b-col lg="7" md="12" sm="12">
          <b-row align-h="between">
            <b-col lg="4" md="4" sm="12" class="mb-1">
              <div class="form-select">
                Show
                <b-form-select
                  v-model="perPage"
                  :options="options"
                  style="width: 60px;"
                  size="sm"
                ></b-form-select>
                Entries
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="5" md="12" sm="12" class="mb-1">
          <b-row align-h="end">
            <SearchBar :hasToolTip="true" :hasErrorMessage="true">
              <template #input>
                <b-form-input v-model="filter" type="search" placeholder="Search" />
              </template>
              <template #errorMessage>
                <small class="text-danger" v-if="!validQuery">Invalid filter, performing regular search.</small>
              </template>
            </SearchBar>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div v-if="hasData">
            <b-table
              responsive
              class="table-with-actions-min-height px-2"
              striped
              outlined
              :items="networkConnInfo"
              :fields="tableFields"
              :per-page="perPage"
              :current-page="currentPage"
              :filter="filter"
              @filtered="onFiltered"
              select-mode="single"
              selectable
            >
              <template #cell(destIps)="row">
                <Ips
                  :ipInfo="row.item.destIps"
                  :uniqueId="row.item.program">
                </Ips>
              </template>
              <template #cell(protocol)="row">
                <Protocols
                  :protocolInfo="row.item.protocol"
                  :uniqueId="row.item.program">
                </Protocols>
              </template>
              <template #cell(totalConnectionCount)="row">
                {{ formatNumberWithCommas(row.value) }}
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="paginationTotalRows"
              :per-page="perPage"
              align="right"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-2 mx-1"
            />
          </div>
          <div v-else-if="loaded" class="mx-1 pl-1 mb-1">
            {{ $t("No Data Available") }}
          </div>
          <SpinnerCmpt v-else class="mb-1"></SpinnerCmpt>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import SearchBar from '@/xvisor/components/SearchBar.vue';
import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
import collapseText from '@/xvisor/utilities/collapseText';
import hostnameFormatter from '@/xvisor/utilities/hostnameFormatter';
import Ips from '@/xvisor/components/xtend/tabs/TrafficTableDestIps.vue';
import Protocols from '@/xvisor/components/xtend/tabs/TrafficTableProtocols.vue';
import XtendHeatmap from '@/xvisor/components/xtend/Heatmap.vue';
import formatNumberWithCommas from '@/xvisor/utilities/addCommaToNumber';

export default {
  props: {
    timeRange: {
      type: Object,
      required: true,
    },
    urls: {
      type: Object,
      required: true,
    },
  },
  components: {
    SearchBar,
    SpinnerCmpt,
    Ips,
    Protocols,
    XtendHeatmap,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      options: [
        { value: 5, text: '5' },
        { value: 10, text: '10' },
      ],
      filter: null,
      filteredLength: null,
      tableFields: [
        { key: 'program', label: 'Program', sortable: true },
        { key: 'destIps', label: 'Destination IPs', sortable: false },
        { key: 'protocol', label: 'Protocol', sortable: false },
        { key: 'totalConnectionCount', label: 'Total Connections', sortable: true },
      ],
      networkConnInfo: [],
      loaded: false,
      validQuery: true,
      url: this.urls.networkConnectionsUrl,
    };
  },
  computed: {
    hasData() {
      return this.loaded && this.networkConnInfo && this.networkConnInfo.length > 0;
    },
    paginationTotalRows() {
      return this.filteredLength;
    },
    processFilter() {
      const retVal = this.networkConnInfo;
      return retVal;
    },
  },
  mounted() {
    this.httpGet();
  },
  methods: {
    httpGet() {
      this.loaded = false;
      this.$http
        .get(this.url, {
          params: {
            start: this.timeRange.start.toISOString(),
            end: this.timeRange.end.toISOString(),
          },
        })
        .then((response) => { this.networkConnInfo = response.data; })
        .finally(() => { this.loaded = true; });
    },
    onFiltered(filteredItems) {
      this.filteredLength = filteredItems.length;
      this.currentPage = 1;
    },
    collapseText,
    hostnameFormatter,
    formatNumberWithCommas,
  },
  watch: {
    networkConnInfo() {
      this.filteredLength = this.processFilter.length;
    },
    timeRange() {
      this.httpGet();
    },
  },
};
</script>

<style scoped>
.form-select{
  min-width: 160px;
  width: 20%;
}
</style>
