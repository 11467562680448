export default {
  docker: '#0db7ed',
  firefox: '#FF423E',
  instagram: '#B500FE',
  netflex: '#DB040F',
  outlook: '#47DCFC',
  spotify: '#1DB954',
  slack: '#ECB22D',
  teams: '#4E5FBF',
  whatsapp: '#26D36C',
  xbox: '#107C0F',
  unknown: '#A0AEC0',
};
