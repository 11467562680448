<template>
  <div>
    <b-card id="xtendInfo" class="mx-n2">
      <b-row align-v="center">
        <b-col cols="6">
          <XtendInfo :url="urls.info" :timeRange="timeRange"></XtendInfo>
        </b-col>
        <b-col cols="6">
          <TimeRangeSelector
            :minDate="minDate"
            :maxDate="maxDate"
            :initRange="timeRange"
            @change-time-range="updateStateTimeRange"
          ></TimeRangeSelector>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <TimeBarAlertsTroubleshooting
            :alertCountUrl="urls.xTendAlertCount"
            :timeRange="timeRange"
            :condensedBar="true"
            @update-short-time-range="(newRange) => { this.shortTimeRange = newRange; }"
            @update-focused-alert="(alert) => { this.focusedAlert = alert; }"
            class="m-n2"
          ></TimeBarAlertsTroubleshooting>
        </b-col>
      </b-row>
    </b-card>
    <XtendTabs
      :timeRange="timeRange"
      :shortTimeRange="shortTimeRange"
      :urls="urls"
      :defaultAlertTab="defaultAlertTab"
      class="xtendContent"
    ></XtendTabs>
  </div>
</template>

<script>
import IssueTimeBarEventBus from '@/xvisor/bus/IssueTimeBarEventBus';
import TimeBarAlertsTroubleshooting from '@/xvisor/components/issue/TimeBarAlertsTroubleshooting.vue';
import XtendInfo from '@/xvisor/components/xtend/XtendInfo.vue';
import XtendTabs from '@/xvisor/components/xtend/XtendTabs.vue';
import timeRangeMixin from '@/xvisor/components/timeRangeMixin';

export default {
  components: {
    XtendInfo,
    TimeBarAlertsTroubleshooting,
    XtendTabs,
  },
  props: {
    defaultAlertTab: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [timeRangeMixin],
  computed: {
    urls() {
      const xomesUrlPrefix = `/xomes/${this.$route.params.id}`;
      const xtendsUrlPrefix = `/xtends/${this.$route.params.id}`;
      return {
        xTendAlertCount: `${xtendsUrlPrefix}/alerts/countts.json`,
        info: `${xtendsUrlPrefix}/info.json`,
        alertId: undefined,
        processCreationUrls: {
          processCreationsUrl: `${xtendsUrlPrefix}/processcreation.json`,
          topProgramsUrl: `${xtendsUrlPrefix}/topprocesses.json`,
        },
        networkConnectionUrls: {
          networkConnectionsUrl: `${xtendsUrlPrefix}/networkconnectionprograms.json`,
          topAsUrl: `${xtendsUrlPrefix}/topasns.json`,
        },
        xtendAlertsUrlPrefix: `${xtendsUrlPrefix}`,
        xtendPackageUrl: `${xtendsUrlPrefix}/packages.json`,
        metrics: {
          cpuUsageUrls: {
            usageAndBaselineUrl: `${xomesUrlPrefix}/metric/cpuusagets.json`,
            eventUrl: `${xomesUrlPrefix}/events/cpuusage.json`,
          },
          memUsageUrls: {
            usageAndBaselineUrl: `${xomesUrlPrefix}/metric/memoryusagets.json`,
            eventUrl: `${xomesUrlPrefix}/events/memusage.json`,
          },
        },
        probes: {
          wifiStats: `${xtendsUrlPrefix}/wifistats.json`,
          xTendAlertCount: `${xtendsUrlPrefix}/alerts/countts.json`,
          xTendEventCount: `${xomesUrlPrefix}/events/countts.json`,
          probesTable: `${xomesUrlPrefix}/targets/list.json`,
        },
        statusUrls: {
          systemInfoUrl: `${xtendsUrlPrefix}/systeminfo.json`,
          cpuUsageUrl: `${xtendsUrlPrefix}/cpu.json`,
          cpuEventUrl: `${xtendsUrlPrefix}/cpu/events.json`,
          memoryUsageUrl: `${xtendsUrlPrefix}/memory.json`,
          memoryEventUrl: `${xtendsUrlPrefix}/memory/events.json`,
          wifiStatsUrl: `${xtendsUrlPrefix}/wifitrxsignalstrength.json`,
          wifiStatsEventUrl: `${xtendsUrlPrefix}/wifi/signal/events.json`,
          xtendEventsCountUrl: `${xtendsUrlPrefix}/eventscount.json`,
        },
        wireless: {
          signalStrengthUrls: {
            statisticAndBaselineUrl: `${xtendsUrlPrefix}/wifitrxsignalstrength.json`,
            eventUrl: `${xomesUrlPrefix}/events/signalstrength.json`,
          },
          transmitRateUrls: {
            statisticAndBaselineUrl: `${xtendsUrlPrefix}/wifitrxtransmitrate.json`,
            eventUrl: `${xomesUrlPrefix}/events/transmitrate.json`,
          },
          receiveRateUrls: {
            statisticAndBaselineUrl: `${xtendsUrlPrefix}/wifitrxreceiverate.json`,
            eventUrl: `${xomesUrlPrefix}/events/receiverate.json`,
          },
        },
      };
    },
  },
  mounted() {
    IssueTimeBarEventBus.$on('update-filters', (filters) => { this.shortTimeRange = filters.shortTimeRange; });
  },
};
</script>

<style lang="scss" scoped>
.card-body {
  padding: 0.5rem 1.25rem;
}

// For medium to mobile screens.
#xtendInfo {
  position: fixed;
  top: 70px;
  margin: 1.3rem 0.5rem 0;
  width: calc(100% - 1rem);
  border-radius: 0.428rem !important;
  z-index: 12;
  margin-top: -0.9rem;
}

.xtendContent {
  margin-top: 4.5rem;
}

// For large screens.
@media (min-width: 1200px) {
  #xtendInfo {
    width: calc(100% - 4.6rem);
  }
}

.menu-expanded #xtendInfo {
  width: calc(100% - 4.6rem - 210px);
}
</style>
