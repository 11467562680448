<template>
  <div v-if="hasData" style="height: 150px">
    <e-chart :options="chartOptions" autoresize />
  </div>
  <div v-else-if="loaded" class="d-flex justify-content-center p-2 mt-1">No Data Available</div>
  <SpinnerCmpt v-else></SpinnerCmpt>
</template>
<script>
import moment from 'moment';

import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
import asnPaletteShade from '@/xvisor/constants/asnColorPallete';
import colorPalatte from '@/xvisor/constants/colorPalette';
import granularity from '@/xvisor/utilities/granularity';
import processPaletteShade from '@/xvisor/constants/processColorPalette';
import themeStyle from '@/xvisor/utilities/themeStyle';

// Indexes for heatmap data: [x, y, count, entity, timestamp, value, metadata].
const COUNT = 2;
const ENTITY = 3;
const VALUE = 5;

// Generate random hexidecimal string for the color.
const generateRandomColor = () => {
  const getBrightColor = () => Math.floor(Math.random() * 128) + 128;
  const red = getBrightColor().toString(16).padStart(2, '0');
  const green = getBrightColor().toString(16).padStart(2, '0');
  const blue = getBrightColor().toString(16).padStart(2, '0');

  return `#${red}${green}${blue}`;
};

// Removes 'exe' to make sure mappings to colors works for both Windows and Linux.
const removeExeExtension = (string) => {
  if (string.endsWith('.exe')) {
    return string.slice(0, -4);
  }
  return string;
};

// A closure for pre-selected colors and already selected colors.
const createColorPicker = () => {
  const colorMap = { ...asnPaletteShade, ...processPaletteShade };
  return (value) => {
    if (colorMap[value]) {
      return colorMap[value];
    }

    let randomColor = generateRandomColor();
    while (randomColor in colorMap) {
      randomColor = generateRandomColor();
    }
    colorMap[value] = randomColor;
    return randomColor;
  };
};

// Initialize closure.
const pickColor = createColorPicker();

export default {
  components: {
    SpinnerCmpt,
  },
  props: {
    timeRange: {
      type: Object,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
  watch: {
    timeRange() {
      this.httpGet();
    },
  },
  data() {
    return {
      series: [],
      loaded: false,
    };
  },
  mounted() {
    this.httpGet();
  },
  computed: {
    hasData() {
      return this.loaded && this.series.length > 0;
    },
    formatData() {
      return this.series.map((item, idx) => {
        const {
          count,
          entity,
          timestamp,
          value,
          metadata,
        } = item;
        const formatedTimestamp = moment.utc(timestamp).local().format('MMM DD, h:mm A');
        const xAxis = Math.floor(idx / 5);
        const yAxis = 5 - (idx % 5) - 1;
        if (!value) {
          return [xAxis, yAxis, count, entity, formatedTimestamp, 'unknown', metadata];
        }
        return [xAxis, yAxis, count, entity, formatedTimestamp, value, metadata];
      });
    },
    chartOptions() {
      return {
        grid: {
          show: true,
          height: '20%',
          top: 30,
          left: 10, // change this back to 15
          right: 5,
        },
        xAxis: {
          type: 'category',
          name: 'Time',
          boundaryGap: true,
          axisLabel: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitArea: {
            show: false,
          },
        },
        yAxis: {
          type: 'category',
          data: [0, 1, 2, 3, 4],
          axisLabel: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitArea: {
            show: false,
          },
        },
        series: [
          {
            name: 'Data',
            type: 'heatmap',
            data: this.formatData,
            itemStyle: {
              borderColor: themeStyle.styleToggle(colorPalatte.white, colorPalatte.blueLightBackground),
              borderWidth: 2,
              color(params) {
                const values = params.value;
                const count = values[COUNT];
                if (count === 0) {
                  return themeStyle.styleToggle(colorPalatte.blueLightBackground, colorPalatte.white);
                }
                let value = values[VALUE];
                if (values[ENTITY] === 'process') {
                  value = removeExeExtension(value);
                }
                return pickColor(value);
              },
            },
          },
        ],
        tooltip: {
          trigger: 'item',
          formatter: (params) => {
            const { data } = params;
            const [count, entity, timestamp, value, metadata] = data.slice(2);
            const valueDisplay = count > 0 ? `Top ${this.capitalizeString(entity)}: ${value}` : 'No User Activity';
            const countDisplay = count > 0 ? `Count: ${count} <br>` : '';
            const metadataDetails = Object
              .entries(metadata)
              .map((obj) => {
                const [key, val] = obj;
                return `${this.capitalizeString(key)}: ${val} <br>`;
              });
            return `
              <div class="apache-echarts-tooltop">
                ${timestamp} <br>
                ${valueDisplay} <br>
                ${metadataDetails}
                ${countDisplay}
              </div>
            `;
          },
        },
      };
    },
  },
  methods: {
    httpGet() {
      this.loaded = false;
      this.$http
        .get(this.url, {
          params: {
            start: this.timeRange.start.toISOString(),
            end: this.timeRange.end.toISOString(),
            stepMinutes: granularity.granularity72(this.timeRange),
          },
        })
        .then((response) => { this.series = response.data; })
        .finally(() => { this.loaded = true; });
    },
    capitalizeString(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
};
</script>
