export default {
  699: '#FF9900', // Amazon
  7922: '#35353B', // Comcast
  8068: '#00A1F1', // Microsoft
  8069: '#00A1F1', // Microsoft
  8070: '#00A1F1', // Microsoft
  8071: '#00A1F1', // Microsoft
  8072: '#00A1F1', // Microsoft
  8073: '#00A1F1', // Microsoft
  8074: '#00A1F1', // Microsoft
  8075: '#00A1F1', // Microsoft
  15169: '#008744', // Google
  22859: '#008744', // Google
  394507: '#008744', // Google
  394639: '#008744', // Google
  13335: '#F6821F', // Cloudflare
  16509: '#FF9900', // Amazon
};
