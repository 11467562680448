<template>
  <e-chart
    v-if="hasData"
    :options="chartOptions"
    autoresize
    class="apache-echarts-default-graph"
  ></e-chart>
  <div v-else-if="loaded">
    {{ $t("No Data Available") }}
  </div>
  <SpinnerCmpt v-else></SpinnerCmpt>
</template>

<script>
import moment from 'moment';

import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
import colorPalette from '@/xvisor/constants/colorPalette';
import colorPaletteShade from '@/xvisor/constants/colorPaletteShade';
import granularity from '@/xvisor/utilities/granularity';
import themeStyle from '@/xvisor/utilities/themeStyle';
import timeFormat from '@/xvisor/utilities/timeFormat';

export default {
  components: {
    SpinnerCmpt,
  },
  props: {
    timeRange: {
      type: Object,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      barNames: {
        processCreation: 'Process Creation',
        networkConnection: 'Network Connection',
      },
      colorMap: {
        processCreation: colorPaletteShade.blue5,
        networkConnection: colorPaletteShade.green4,
      },
      seriesData: {},
      loaded: true,
      chartTitle: 'Activity',
    };
  },
  watch: {
    url() {
      this.httpGet();
    },
    timeRange() {
      this.httpGet();
    },
  },
  mounted() {
    this.httpGet();
  },
  computed: {
    hasData() {
      return this.loaded && this.seriesData
        && (this.dataNotEmpty(this.seriesData.processCreation)
            || (this.dataNotEmpty(this.seriesData.networkConnection))
        );
    },
    toggleColor() {
      return themeStyle.styleToggle(colorPalette.black, colorPalette.white);
    },
    timestamps() {
      let timestampList = [];
      if (this.seriesData.processCreation.length > 0) {
        timestampList = this.seriesData.processCreation.map((ts) => ts.timestamp);
      } else if (this.seriesData.networkConnection.length > 0) {
        timestampList = this.seriesData.processCreation.map((ts) => ts.timestamp);
      }
      return timestampList;
    },
    timeSeriesFormat() {
      return timeFormat.momentFormat(this.timestamps, new Date());
    },
    formattedSeries() {
      const series = [];
      const entries = Object.entries(this.seriesData);
      for (let i = 0; i < entries.length; i += 1) {
        const [dataType, dataList] = entries[i];
        const timeCount = dataList.map((dataPoint) => [dataPoint.timestamp, dataPoint.count]);
        series.push(
          {
            name: this.barNames[dataType],
            type: 'bar',
            stack: 'bar',
            data: timeCount,
            itemStyle: {
              color: this.colorMap[dataType], // Set a specific color for all bars in this series
            },
          },
        );
      }
      return series;
    },
    chartOptions() {
      return {
        tooltip: {
          trigger: 'axis',
          formatter: (info) => `
            <div class="apache-echarts-tooltip">
              ${this.chartTitle} / ${moment(info[0].value[0]).format(this.timeSeriesFormat)}
              <br>
              ${this.chartTooltipContent(info)}
            </div>
          `,
        },
        grid: [
          {
            left: 50,
            right: 50,
            top: 30,
            bottom: 30,
          },
        ],
        xAxis: [
          {
            type: 'time',
            boundaryGap: true,
            splitLine: {
              show: false,
            },
            axisTick: {
              lineStyle: {
                color: this.toggleColor,
              },
            },
            axisLine: {
              lineStyle: {
                color: this.toggleColor,
              },
            },
            axisLabel: {
              formatter: (value) => moment(value).format(this.timeSeriesFormat),
              fontSize: 9,
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            name: 'Count',
            nameLocation: 'center',
            nameGap: 35,
            axisTick: {
              lineStyle: {
                color: this.toggleColor,
              },
            },
            axisLine: {
              lineStyle: {
                color: this.toggleColor,
              },
            },
            axisLabel: {
              fontSize: 9,
            },
          },
        ],
        series: this.formattedSeries,
      };
    },
  },
  methods: {
    dataNotEmpty(data) {
      return data && data.length > 0 && !data.every((dataPoint) => dataPoint.count === 0);
    },
    chartTooltipContent(info) {
      let result = '';
      for (let i = 0; i < info.length; i += 1) {
        result += `${info[i].marker} ${info[i].seriesName}: ${info[i].value[1]} <br>`;
      }
      return result;
    },
    httpGet() {
      this.loaded = false;
      const seriesRequest = this.$http.get(this.url, {
        params: {
          start: this.timeRange.start.toISOString(),
          end: this.timeRange.end.toISOString(),
          stepMinutes: granularity.granularity12(this.timeRange),
        },
      });
      seriesRequest
        .then((tsRes) => {
          this.seriesData = tsRes.data;
        })
        .finally(() => {
          this.loaded = true;
        });
    },
  },
};
</script>
