<template>
  <b-table
    :items="vulnerabilities"
    :fields="fields"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
  >
    <template #cell(id)="data">
      <a :href="cveLink(data.value)" target="_blank">{{ data.value }}</a>
    </template>
    <template #cell(score.base)="data">
      <VulnerabilityScore :score="data.value" />
    </template>
    <template #cell(cisaKev)="data">
      <div class="d-flex justify-content-center">
        <b-icon
          v-if="data.value"
          icon="check-circle-fill"
          variant="danger"
          style="width:20px;height:20px;"
        >
        </b-icon>
      </div>
    </template>
    <template #head(cisaKev)>
        <span
          class="d-flex align-items-center"
        >
          KEV
          <b-icon
            icon="question-circle-fill"
            class="mx-0-5"
            style="width:15px;height:15px;"
            v-b-tooltip.hover
            :title="cisaKevTooltipText"
          >
          </b-icon>
        </span>
    </template>
  </b-table>
</template>

<script>
import VulnerabilityScore from '@/xvisor/components/xtend/tabs/VulnerabilityScore.vue';

export default {
  props: {
    vulnerabilities: {
      type: Array,
      required: true,
    },
  },
  components: {
    VulnerabilityScore,
  },
  data() {
    return {
      cisaKevTooltipText: `
      Known Exploited Vulnerability - (https://www.cisa.gov/known-exploited-vulnerabilities-catalog)`,
      sortBy: 'score.base',
      sortDesc: true,
      fields: [
        {
          key: 'id',
          label: 'Name',
          sortable: true,
          class: 'col-2',
        },
        { key: 'description', label: 'Description' },
        { key: 'score.base', label: 'Score', sortable: true },
        { key: 'cisaKev', label: 'KEV', sortable: true },
      ],
    };
  },
  methods: {
    cveLink(cveId) {
      return `https://nvd.nist.gov/vuln/detail/${cveId}`;
    },
  },
};
</script>
