<template>
 <div>
    <b-card no-body class="mx-n3">
      <b-row class="mx-1 mt-2 mb-1" align-h="between">
        <b-col>
          <h4>System Information</h4>
          <SystemInfo
           :urls="urls"
          ></SystemInfo>
          <h4>System Metrics</h4>
          <b-row align-h="between">
            <b-col lg="6" md="6" sm="12" class="mb-1">
              <h6>Activity</h6>
              <XtendEventCounts
                :timeRange="timeRange"
                :url="urls.xtendEventsCountUrl"
              >
              </XtendEventCounts>
            </b-col>
            <b-col lg="6" md="6" sm="12" class="mb-1">
              <h6>CPU Usage</h6>
              <XtendStatus
                :timeRange="timeRange"
                metric="cpu"
                :url="urls.cpuUsageUrl"
                :eventUrl="urls.cpuEventUrl"
              >
              </XtendStatus>
            </b-col>
          </b-row>
          <b-row align-h="between">
            <b-col lg="6" md="6" sm="12" class="mb-1">
              <h6>Memory Usage</h6>
              <XtendStatus
                :timeRange="timeRange"
                metric="memory"
                :url="urls.memoryUsageUrl"
                :eventUrl="urls.memoryEventUrl"
              >
              </XtendStatus>
            </b-col>
            <b-col lg="6" md="6" sm="12" class="mb-1">
              <h6>WiFi Signal Strength</h6>
              <XtendStatus
                :timeRange="timeRange"
                metric="wifi"
                :url="urls.wifiStatsUrl"
                :eventUrl="urls.wifiStatsEventUrl"
              >
              </XtendStatus>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import XtendStatus from '@/xvisor/components/xtend/tabs/XtendStatus.vue';
import SystemInfo from '@/xvisor/components/xtend/tabs/SystemInfo.vue';
import XtendEventCounts from '@/xvisor/components/xtend/tabs/XtendEventCounts.vue';

export default {
  components: {
    XtendStatus,
    SystemInfo,
    XtendEventCounts,
  },
  props: {
    timeRange: {
      type: Object,
      required: true,
    },
    urls: {
      type: Object,
      required: true,
    },
  },
};
</script>
